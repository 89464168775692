import React from "react"
import { color } from "../defaults/styles/theme";
import { wrapper } from "../defaults/styles/elements";
import { css } from "@emotion/react";
import mq from "../defaults/styles/mediaquerys";
import { ArrowLink } from "../defaults/styles/elements"
import Info from "../defaults/assets/svg/info.svg";
import BackgroundImage from 'gatsby-background-image-es5';


const About = ({data}) => {
    return (
      <>
        <BackgroundImage
          fluid={data.childImageSharp.fluid}
          css={css`
            position: relative;
            display: flex;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            padding: 0 0 8em;
          `}
          style={{backgroundPositionY: "90%"}}
        >
          <div
            css={css`
              width: 100%;
              height: 2em;
              top: 0;
              left: 0;
              position: absolute;
              background: white;
            `}
          ></div>
          <div
            css={[
              wrapper,
              css`
                width: 100%;
                position: relative;
              `,
            ]}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  max-width: 35em;
                  padding: 4rem 2rem;
                  border-radius: 5px;
                  background: ${color.main_dark};
                  ${mq[1]} {
                    transform: translateX(10%);
                  }
                  ${mq[2]} {
                    transform: translateX(25%);
                  }
                  h2 {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 700;
                    color: white;
                    line-height: 1em;
                  }
                  p {
                    margin-bottom: 0.9em;
                    line-height: 1.9em;
                    font-size: 0.85rem;
                    color: white;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;
                  `}
                >
                  <div
                    css={css`
                      padding: 0.5em 0.35rem;
                      background: white;
                      border-radius: 5px;
                      line-height: 1em;
                      margin-right: 0.5em;
                      svg {
                        height: 1rem;
                        width: auto;
                        line-height: 1em;
                      }
                    `}
                  >
                    <Info />
                  </div>
                  <h2>What is EXPO 9: the Insider Edition?</h2>
                </div>
                <p>
                  On February 25 we are opening our virtual doors up to a selected group of attendees. It has been designed to connect invited guests with the right people to take their ideas, technologies, investments - to the next level. This will be a one day virtual event, kicking off with a live stream bringing all participants up to speed on how to get the most out of their day.
                </p>
                <p>
                  The Breakout Sessions will be the main event this time around, enabling participants to showcase their ideas and exchange in small expert-led groups. These seminars will go deep into a range of current tech and innovation topics, including interactive workshops on the pilot projects of this program, as well as other STARTUP AUTOBAHN specific themes.  
                </p>
                <p>
                  All attendees will receive access to Project Teasers, a series of case studies presenting the projects showcased and are invited to participate in virtual open networking sessions. We are going minimalistic, results-oriented, and functional - in a really good way.
                </p>
                <ArrowLink
                  url="https://startup-autobahn.com"
                  text="More about STARTUP AUTOBAHN"
                  css={css`
                    margin-top: 1.2em;
                  `}
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </>
    )
}

export default About;