import React from "react";
import { Global, css } from "@emotion/react"
import "fontsource-inter/latin.css"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import HeroSection from "../components/main/hero"
import PartnerSection from "../components/main/partnersection"
import Teaser from "../components/main/teaser"
import About from "../components/main/about"
import Numbers from "../components/main/numbers"
import GeneralFooter from '../components/general_footer';
import { graphql } from "gatsby"
import Contact from "../components/main/contact"
import FAQ from "../components/faq"
import Updates from "../components/updates"
// import Overview from "../components/main/overview"
// import OverviewIntro from "../components/main/overview_intro";
// import MediaPartners from '../components/main/mediapartner';
// import ThanksHDM from '../components/main/thankshdm';
// import PressRelease from '../components/main/pressrelease';
// import PressFooter from '../components/pressfooter';

const Index = ({ data }) => {
  return (
    <>
      <Global
          styles={css`
              body {
                  background: ${color.main_dark}
              }
          `}
      />
      <Layout dark>
        <HeroSection data={ data } />
        <PartnerSection />
        <Teaser />
        <About data={data.expobackground} />
        <Numbers />
        {/* <OverviewIntro /> */}
        {/* <Overview /> */}
        {/* <PressRelease /> */}
        {/* <MediaPartners /> */}
        {/* <ThanksHDM /> */}
        {/* <PressFooter theme={ color.main_light } /> */}
        <Updates />
        <FAQ />
        <Contact />
        <GeneralFooter theme={ color.main_dark } />
      </ Layout>
    </>
  )
}

export const query = graphql`
         query {
           file(relativePath: { eq: "SA_ExpoDay8_-169bw.nosync.jpg" }) {
             childImageSharp {
               fluid (
                 maxWidth: 1900,
                 quality: 60,
                 ) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           expobackground: file(relativePath: { eq: "SA_ExpoDay8_-133.jpg" }) {
             childImageSharp {
               fluid (
                 #grayscale: true,
                 maxWidth: 1900,
                 quality: 60
                 ) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }
       `

export default Index;