import React from "react"
import { color } from "../defaults/styles/theme";
import { wrapper, ArrowLink } from "../defaults/styles/elements";
import { css } from "@emotion/react";
import mq from "../defaults/styles/mediaquerys";


const content = "<p>Join for a day of <b>new technologies</b>, <b>breakout sessions</b> with experts, curated <b>networking</b>, and the opportunity to meet and discuss <b>future collaborations</b> with our program startups and corporate partners. This event is not open to the public.</p>"



const Teaser = () => {
    return (
      <section
        css={css`
          padding: 6em 0;
          background: white;
          color: ${color.main_dark};
        `}
      >
        <div
          css={[
            wrapper,
            css`
              display: flex;
              max-width: 35rem;
              flex-direction: column;
              padding: 0 1.5em 0 3em;
              ${mq[1]} {
                max-width: 45rem;
                padding: 3em;
              }
              ${mq[2]} {
                padding-left: 5em;
                max-width: 50rem;
              }
            `,
          ]}
        >
          <h2 css={css`
            margin-bottom: 0;
          `}>EXPO 9: the Insider Edition</h2>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            css={css`
              margin-top: 0.75em;
              ${mq[0]} {
                margin-top: 1em;
              }
              ${mq[1]} {
                margin-top: 2em;
              }
              p {
                font-size: 1.15em;
                font-weight: 400;
                line-height: 1.6em;
                margin-bottom: 0.5em;
                ${mq[1]} {
                  font-size: 1.35em;
                }
                ${mq[1]} {
                  font-size: 1.75em;
                }
              }
            `}
          />
          <ArrowLink
            text="More about STARTUP AUTOBAHN"
            url="https://startup-autobahn.com"
            css={css`
              color: ${color.main_dark};
              margin-top: 1em;
              svg {
                stroke: ${color.main_dark};
              }
            `}
          />
        </div>
      </section>
    )
}

export default Teaser;