import React from 'react';
import { css } from '@emotion/react';
import { wrapper } from '../defaults/styles/elements';
import mq from '../defaults/styles/mediaquerys';
import { color } from '../defaults/styles/theme';
import Link from '../link';


const numbers_data = [
  {
    "number": "29",
    "title": "Platform Partners",
    "url": "/partners"
  },
  {
    "number": "4000+",
    "title": "Startups screened",
    "url": ""
  },
  {
    "number": "259",
    "title": "Startups accelerated",
    "url": ""
  },
  {
    "number": "90+",
    "title": "Implementations in production",
    "url": ""
  },
]


const NumbersEl = ({data}) => {
  const elStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
  
  return (
    <Link
      to={data.url}
      css={css`
        text-decoration: none;
        color: ${color.main_dark};
        background: ${color.accent_light};
        border-radius: 5px;
        flex: 0 0 100%;
        margin: 0.25em 0;
        display: flex;
        flex-direction: column;
        pointer-events: ${data.url.length > 0 ? "auto" : "none"};
        ${mq[0]} {
          flex: 0 0 calc(100% / 2 - 0.25em);
        }
        ${mq[1]} {
          flex: 0 0 calc(100% / 3 - 0.5em);
        }
        ${mq[2]} {
          flex: 0 0 calc(100% / 4 - 0.75em);
        }
      `}
    >
      <div
        css={[
          elStyle,
          css`
            padding: 2em 2em 1em 2em;
          `,
        ]}
      >
        <p
          css={css`
            margin: 0;
            font-size: 3em;
            font-weight: 800;
            letter-spacing: 0.05em;
            line-height: 1em;
          `}
        >
          {data.number}
        </p>
      </div>
      <div
        css={[
          elStyle,
          css`
            flex: 0 0 2.5em;
            position: relative;
          `,
        ]}
      >
        <p
          css={css`
            margin: 0 0 2em 0;
            font-size: 0.75em;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            text-align: center;
          `}
        >
          {data.title}
        </p>
      </div>
    </Link>
  )
}


const Numbers = () => {
  return (
    <section
      css={css`
        background: white;
        width: 100%;
        padding-bottom: 5rem;
      `}
    >
      <div css={wrapper}>
        <div
          css={[
            css`
              margin-top: -1.5em;
              width: 100%;
              z-index: 1;
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            `,
          ]}
        >
          <p
            css={[
              css`
                position: absolute;
                bottom: calc(100% + 0.5em);
                right: 1.5em;
                margin: 0;
                font-size: 0.6em;
                font-weight: 800;
                letter-spacing: 0.07em;
                text-transform: uppercase;
                color: white;
              `,
            ]}
          >
            September 24th, 2020 – EXPO Day 8
          </p>

          {numbers_data.map((el, index) => (
            <NumbersEl data={el} key={index} />
          ))}
        </div>

      </div>
    </section>
  )
}

export default Numbers;